import React from "react"
import LinkButton from "../Shared/LinkButton"
import { Animate } from "../../utils/Transition"

const TEXT_TRANSITION = "all .8s ease-in-out"
const TEXT_TRANSITION_DELAY = "all .8s ease-in-out 800ms"
const IMAGE_TRANSITION =
  "all 1.3s cubic-bezier(.77,0,.175,1),-webkit-transform 1.3s cubic-bezier(.77,0,.175,1)"

/**
 * Banner Text Transition Config
 */
const textDuration = 300

const defaultTextStyle = {
  transition: TEXT_TRANSITION,
  transform: "translateY(8px)",
  opacity: 0,
}

const transitionTextStyles = {
  entering: {
    opacity: 0,
    transform: "translateY(8px)",
    transition: TEXT_TRANSITION_DELAY,
  },
  entered: {
    opacity: 1,
    transform: "translateY(0px)",
    transition: TEXT_TRANSITION_DELAY,
  },
  exiting: {
    opacity: 1,
    transform: "translateY(0px)",
    transition: TEXT_TRANSITION,
  },
  exited: {
    opacity: 0,
    transform: "translateY(12px)",
    transition: TEXT_TRANSITION,
  },
}

/**
 * Banner Image Transition Config
 */
const imageDuration = 200

const defaultImageStyle = {
  transition: IMAGE_TRANSITION,
  opacity: 0,
}

const transitionImageStyles = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 1 },
  exited: { opacity: 0 },
}

const BannerSlide = props => {
  const {
    headline,
    subheadline,
    primaryButton,
    secondaryButton,
    image,
    isCurrentSlide,
  } = props

  return (
    <React.Fragment>
      <Animate
        className="absolute top-0 w-full h-full"
        timeout={imageDuration}
        defaultStyle={defaultImageStyle}
        transitionStyles={transitionImageStyles}
        in={isCurrentSlide}
        delay={0}
      >
        <div
          className="absolute top-0 left-0 right-0 bottom-0 transform-fix"
          style={{
            backgroundImage: `url('${image.file.url}')`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            zIndex: isCurrentSlide ? 100 : "initial",
          }}
        />
        <div
          className="absolute top-0 left-0 bottom-0 right-0 transform-fix"
          style={{
            background:
              "linear-gradient(150deg, rgba(40, 35, 36, 0.85), rgba(35, 31, 32, 0))",
            zIndex: isCurrentSlide ? 100 : "initial",
          }}
        />
      </Animate>
      <div
        className="absolute z-0 top-0 left-0 bottom-0 right-0"
        style={{ zIndex: isCurrentSlide ? 100 : "initial" }}
      >
        <div className="mx-auto max-w-screen-xl px-9 flex w-full h-full items-center">
          <div className="w-full">
            <div className="lg:grid lg:grid-cols-12 lg:gap-8">
              <div className="md:max-w-2xl lg:col-span-6 lg:text-left lg:flex lg:items-center">
                <Animate
                  timeout={textDuration}
                  in={isCurrentSlide}
                  defaultStyle={defaultTextStyle}
                  transitionStyles={transitionTextStyles}
                  delay={300}
                  appear
                >
                  <h2 className="-mt-2 md:mt-4 text-3xl lg:text-4xl tracking-tight leading-10 font-extrabold text-white sm:mt-5 sm:leading-none sm:text-6xl lg:mt-6 lg:text-5xl xl:text-6xl">
                    {headline}
                  </h2>
                  <p className="mt-3 w-5/6 text-base md:text-lg text-gray-100 md:text-gray-300 sm:mt-6 sm:text-xl lg:text-lg xl:text-xl">
                    {subheadline}
                  </p>
                  {(primaryButton || secondaryButton) && (
                    <div className="mt-6 md:flex">
                      {primaryButton && (
                        <div className="mb-4 md:mb-0 md:mr-4">
                          <LinkButton.White
                            to={primaryButton.url}
                            className="inline-block"
                          >
                            {primaryButton.label}
                          </LinkButton.White>
                        </div>
                      )}
                      {secondaryButton && (
                        <div>
                          <LinkButton.White
                            to={secondaryButton.url}
                            className="inline-block"
                          >
                            {secondaryButton.label}
                          </LinkButton.White>
                        </div>
                      )}
                    </div>
                  )}
                </Animate>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default BannerSlide
