import React from 'react';
import PropTypes from 'prop-types';
import BannerSlide from './BannerSlide';
import ProgressIndicator from './ProgressIndicator';

const SLIDE_DURATION = 8500; // 8.5 seconds

const Banner = (props) => {
  const { slides } = props;
  const [currentIndex, setCurrentIndex] = React.useState(0);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex(currentIndex + 1 === slides.length ? 0 : currentIndex + 1);
    }, SLIDE_DURATION);

    return () => clearInterval(interval);
  }, [currentIndex, setCurrentIndex, slides.length]);

  return (
    <main
      className="w-full pt-12 pb-12 relative z-0 banner-mobile"
    >
      {props.slides.map((slide, index) => (
        <BannerSlide
          key={slide.id}
          isCurrentSlide={currentIndex === index}
          {...slide}
        />
      ))}
      <ProgressIndicator
        currentIndex={currentIndex}
        duration={SLIDE_DURATION}
        slides={slides}
      />
    </main>
  );
};

Banner.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.shape({
    headline: PropTypes.string.isRequired,
    subheadline: PropTypes.string.isRequired,
    image: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string.isRequired
      })
    }).isRequired,
    primaryButton: PropTypes.shape({
      label: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired
    }),
    secondaryButton: PropTypes.shape({
      label: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired
    })
  }))
};

export default Banner;
