import React from 'react';

const BAR_WIDTH = 80;
const GAP = 10;
const DELAY = 200;

const ProgressBar = () => {
  const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => setLoaded(true), DELAY);
  }, [setLoaded]);

  return (
    <div className="bg-white h-full" style={{ width: loaded ? '100%' : '0%', transition: 'width 6800ms linear' }} />
  );
};


const ProgressIndicator = ({ currentIndex, slides, duration }) => {
  const maxWidth = (slides.length * BAR_WIDTH) + (slides.length - 1) * GAP;

  return (
    <div className="absolute flex justify-center items-center left-0 right-0 bottom-0 z-9" style={{ height: 40, zIndex: 200 }}>
      <div className="w-full flex justify-between" style={{ maxWidth }}>
        {slides.map((slide, index) => (
          <div key={slide.id} className="w-full bg-periwinkle-base" style={{ height: 4, width: BAR_WIDTH}}>
            {currentIndex === index && (
              <ProgressBar />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProgressIndicator;
