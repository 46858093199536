import React from "react"
import Link from "../Link/Link"

const RecentUpdates = ({ updates }) => {
  return (
    <div className="relative bg-gray-50 pt-2 pb-12 md:pt-16 md:pb-16 px-4 sm:px-6 lg:pt-5 lg:px-8">
      <div className="absolute inset-0">
        <div className="bg-white h-1/3 sm:h-2/3" />
      </div>
      <div className="relative max-w-7xl mx-auto">
        <div className="mt-12 grid gap-5 max-w-lg mx-auto lg:grid-cols-3 lg:max-w-none">
          {updates.map(update => (
            <div
              key={update.id}
              className="bg-white rounded-lg shadow overflow-hidden"
            >
              <Link className="h-full flex flex-col" to={update.pageLink.url}>
                <div className="flex-shrink-0 relative">
                  <img
                    className="h-48 w-full object-cover"
                    src={update.image.file.url}
                    alt=""
                  />
                  <div
                    className="absolute top-0 left-0 bottom-0 right-0"
                    style={{
                      background: "rgba(172, 172, 172, 0.2)",
                    }}
                  />
                </div>
                <div className="flex-1 p-6 flex flex-col justify-between">
                  <div className="flex-1 flex flex-col">
                    <h3 className="text-center text-xl leading-7 font-semibold text-gray-900">
                      {update.title}
                    </h3>
                    <p className="mt-2 mb-4 text-center text-base leading-6 text-gray-500">
                      {update.description}
                    </p>
                    <p
                      className="w-full text-center pt-4 border-t border-gray-200 text-navy-base"
                      style={{ marginTop: "auto" }}
                    >
                      {update.pageLink.label} →
                    </p>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default RecentUpdates
