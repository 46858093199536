import React from 'react';
import LinkButton from '../Shared/LinkButton';
import Map from '../../images/map.png';

const MapBlock = (props) => {
  const { title, description, image, button } = props;

  return (
    <div className="py-16">
      <div className="mx-auto px-8 max-w-screen-xl">
        <div className="lg:grid grid-cols-12 gap-10">
          <div
            className="w-full h-52 lg:w-auto lg:h-auto mb-10 lg:mb-0 col-span-12 lg:col-span-7"
            style={{
              backgroundImage: `url('${Map}')`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }}
          >
          </div>
          <div className="col-span-12 lg:col-span-5">
            <div className="w-full bg-white shadow rounded-lg overflow-hidden">
              <div
                className="relative h-52"
                style={{
                  backgroundImage: `url('${image.file.url}')`,
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                }}
              >
                <div
                  className="absolute p-6 top-0 left-0 bottom-0 right-0 flex flex-col justify-center"
                  style={{
                    background: 'linear-gradient(150deg, #3F383A, rgba(35, 31, 32, 0))'
                  }}
                >
                  <h3 className="text-3xl font-semibold text-white mb-2">{title}</h3>
                  <div className="bg-white" style={{ width: 100, height: 3 }} />
                </div>
              </div>
              <div className="p-6">
                <p className="text-base text-gray-900 mb-6">
                  {description}
                </p>
                <LinkButton.Red className="inline-block" to={button.url}>
                  {button.label}
                </LinkButton.Red>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MapBlock;
