import React from 'react';
import PropTypes from 'prop-types';
import BlockquoteIcon from '../Shared/Icons/Blockquote';

const DISPLAY_COUNT = 2;

const Testimonials = ({ testimonials }) => {
  // Show a max of two testimonials
  const cappedTestimonials = React.useMemo(() => {
    return testimonials.slice(0, DISPLAY_COUNT);
  }, [testimonials]);

  return (
    <section className="bg-navy-base">
      <div className="max-w-screen-xl mx-auto md:grid md:grid-cols-2 md:px-6 lg:px-8">
        {cappedTestimonials.map((testimonial, index) => (
          <div
            key={testimonial.id}
            className={`py-12 px-4 sm:px-6 md:flex md:flex-col md:py-16 ${index === 0 ? 'md:pl-0 md:pr-10 md:border-r lg:pr-16' : 'md:pl-10 lg:pl-16'} md:border-gray-600`}
          >
            <blockquote className="mt-6 md:flex-grow md:flex md:flex-col">
              <div className="relative text-lg leading-7 font-medium text-white md:flex-grow">
                <BlockquoteIcon />
                <p className="relative">
                  {testimonial.testimonialText}
                </p>
              </div>
              <footer className="mt-6 flex">
                <div className="flex-shrink-0 inline-flex rounded-full border-2 border-white">
                  <div
                    className="h-12 w-12 rounded-full"
                    style={{
                      backgroundImage: `url('${testimonial.memberImage.file.url}')`,
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                    }}
                  />
                </div>
                <div className="ml-4">
                  <div className="text-base leading-6 font-medium text-white">{testimonial.testimonialMemberName}</div>
                  <div className="text-base leading-6 font-medium text-gray-200">{testimonial.memberNameLine2}</div>
                </div>
              </footer>
            </blockquote>
          </div>
        ))}
      </div>
    </section>
  );
};

Testimonials.defaultProps = {
  testimonials: []
};

Testimonials.propTypes = {
  testimonials: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    testimonialText: PropTypes.string.isRequired,
    testimonialMemberName: PropTypes.string.isRequired,
    memberNameLine2: PropTypes.string.isRequired,
    memberImage: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string.isRequired
      })
    }).isRequired
  })).isRequired
};

export default Testimonials;
