import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Banner from '../components/Banner/Banner';
import RecentUpdates from '../components/Updates/RecentUpdates';
import Rates from '../components/Rates/Rates';
import Testimonials from '../components/Testimonials/Testimonials';
import MapBlock from '../components/Map/MapBlock';
import OverdraftModal from '../components/Overdraft/OverdraftModal';

import LostCardBlock from '../components/LostCard/LostCardBlock';

const OVERLAY_DELAY = 1300; // 1.3 second

const IndexPage = (props) => {
  const {
    contentfulHomepageSlider,
    contentfulUpdatesBlock: updatesBlock,
    allContentfulHomeTestimonial: testimonials,
    contentfulMapBlock: mapBlock,
    contentfulLostCardBlock: lostCard,
    contentfulHomepageRates: rates
  } = props.data;

  const [renderOverdraftModal, setRenderOverdraftModal] = React.useState(false);

  React.useEffect(() => {
    setTimeout(setRenderOverdraftModal, OVERLAY_DELAY, true);
  }, [setRenderOverdraftModal]);

  return (
    <React.Fragment>
      <Layout>
        <SEO />
        <Banner slides={contentfulHomepageSlider.banners} />
        <RecentUpdates updates={updatesBlock.updates} />
        <LostCardBlock {...lostCard} />
        <Testimonials testimonials={testimonials.nodes} />
        <Rates {...rates} />
        <MapBlock {...mapBlock} />
        <div className={renderOverdraftModal ? '' : 'hidden'}>
          <OverdraftModal />
        </div>
      </Layout>
    </React.Fragment>
  );
};

export const query = graphql`
  query IndexQuery {
    contentfulHomepageSlider {
      banners {
        id
        headline
        subheadline
        image {
          file {
            url
          }
        }
        primaryButton {
          label
          url
        }
        secondaryButton {
          label
          url
        }
      }
    }
    contentfulUpdatesBlock {
      updates {
        id
        title
        image {
          file {
            url
          }
        }
        description
        pageLink {
          label
          url
        }
      }
    }
    allContentfulHomeTestimonial {
      nodes {
        id
        title
        testimonialText
        testimonialMemberName
        memberNameLine2
        memberImage {
          file {
            url
          }
        }
      }
    }
    contentfulMapBlock {
      id
      title
      description
      image {
        file {
          url
        }
      }
      button {
        label
        url
      }
    }
    contentfulLostCardBlock {
      id
      title
      button {
        label
        url
      }
    }
    contentfulHomepageRates {
      title
      rates {
        id
        rateText
        rateNumber
      }
    }
  }
`;

export default IndexPage;
