import React from 'react';
import PropTypes from 'prop-types';

const Rates = ({ title, rates }) => {
  return (
    <div className="bg-gray-50 pt-16 pb-13">
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-3xl leading-9 font-bold text-gray-900 sm:text-4xl sm:leading-10">
            {title}
          </h2>
          <div className="bg-periwinkle-base mt-4 inline-block" style={{ width: 120, height: 3 }} />
        </div>
      </div>
      <div
        className="mt-8 pb-12 bg-gray-50"
        data-sal="fade"
        data-sal-duration="500"
        data-sal-delay="0"
        data-sal-easing="ease-in-out"
      >
        <div className="relative">
          <div className="absolute inset-0 h-1/2 bg-gray-50" />
          <div className="relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-6xl mx-auto">
              <div className="rounded-lg bg-white shadow sm:grid sm:grid-cols-3">
                {rates.map(rate => (
                  <div key={rate.id} className="flex flex-col border-b border-gray-100 p-6 text-center sm:border-0 sm:border-r">
                    <div className="order-2 mt-2 text-lg leading-6 font-medium text-gray-600" id={rate.id}>
                      {rate.rateText}
                    </div>
                    <div className="order-1 text-4xl leading-none font-extrabold text-periwinkle-base" aria-describedby={rate.id}>
                      {rate.rateNumber}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Rates.propTypes = {
  title: PropTypes.string.isRequired,
  rates: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    rateText: PropTypes.string.isRequired,
    rateNumber: PropTypes.string.isRequired
  })).isRequired
};

export default Rates;
