import React from 'react';
import { navigate } from 'gatsby';

import Modal from '../Shared/Modal/Modal';
import LinkButton from '../Shared/LinkButton';

const modalKey = 'hide-overdraft-modal';

const OverdraftModal = () => {
  const [hideOverdraftModal, setHideOverdraftModal] = React.useState(() => {
    if (typeof localStorage !== 'undefined') {
      return localStorage.getItem(modalKey);;
    }

    return false;
  });

  React.useEffect(() => {
    if (typeof document !== 'undefined') {
      const closeButton = document.querySelector('#overdraft-modal-close');

      if (closeButton) {
        closeButton.focus();
      }
    }
  }, []);

  const handleClick = React.useCallback((redirect) => {
    localStorage.setItem(modalKey, true);
    setHideOverdraftModal(true);

    if (redirect) {
      navigate("/overdraft-coverage");
    }
  }, [setHideOverdraftModal]);

  if (hideOverdraftModal) {
    return null;
  }

  return (
    <Modal
      padded={false}
      onRequestClose={() => handleClick(false)}
      animated
    >
      <img src="/overdraft-background.jpg" alt="Overdraft Coverage" />
      <div className="p-6">
        <p className="text-3xl mb-3 bold">Life Happens!</p>
        <p className="text-lg mb-6">We understand that overdrafts occur from time to time. Click below to learn more about our overdraft options.</p>
        <div className="w-full flex flex-col md:flex-row">
          <LinkButton.Light
            to="/"
            className="flex-1 md:mr-3 block"
            is="button"
            id="overdraft-modal-close"
            onClick={() => handleClick(false)}
          >
            Close
          </LinkButton.Light>
          <LinkButton.Red
            showExternalModal={false}
            to="/"
            className="flex-1 block order-first md:order-none mb-3 md:mb-0"
            onClick={() => handleClick(true)}
          >
            Learn About Overdraft Coverage
          </LinkButton.Red>
        </div>
      </div>
    </Modal>
  );
};

export default OverdraftModal;
